import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  suplementosList:[],
  editaSuplemento:false,
  togleIcon      : false,  
  msj:'',  
}


export const suplementosSlice = createSlice({
  name: 'suplementos',
  initialState,
  reducers: {

    set_suplementosList: (state, action) => {
      state.suplementosList = action.payload
    },

    set_editaSuplemento: (state, action) => {
      state.editaSuplemento = action.payload
    },

    set_togleIconSupl:(state, action)=>{
      state.togleIcon = action.payload
    },

    set_msj: (state, action) => {
      state.msj = action.payload
    },
  },
})

export const { set_suplementosList, set_editaSuplemento, set_togleIconSupl, set_msj } = suplementosSlice.actions