import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pasesList : [],
  editaPase : false,
  togleIcon : false 
}

export const pasesSlice = createSlice({
  name: 'pases',
  initialState,
  reducers: {
    set_pasesList: (state, action) => {
      state.pasesList = action.payload
    },

    set_editaPase: (state, action) => {
      state.editaPase = action.payload
    },

    set_togleIconPase:(state, action)=>{
      state.togleIcon = action.payload
    }
  },
})

export const { set_pasesList, set_editaPase, set_togleIconPase } = pasesSlice.actions