import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  conceptosList  : [],
  editaConcepto : false,
  togleIcon      : false,  
}

export const conceptosSlice = createSlice({
  name: 'conceptos',
  initialState,
  reducers: {
    set_conceptoList:(state, action)=>{
      state.conceptosList = action.payload
    },
    set_resetConcepto:(state, action)=>{
      state.conceptosList = []
    },
    set_togleIcon:(state, action)=>{
      state.togleIcon = action.payload
    }
  },
})

export const { set_conceptoList, set_resetConcepto,set_editaConcepto } = conceptosSlice.actions