import { useState } from 'react';

export const useForm = ( initialForm = {} ) => {
  
    const [ formState, setFormState ] = useState( initialForm );
    const [ loading, setLoading ] = useState( false );
    const [result, setResult] = useState([]);
    const [verAltaAgente, setVerAltaAgente] = useState(false);
    const [verAltaUsuario, setVerAltaUsuario] = useState(false);
    const [verEditVerAgente, setVerEditVerAgente] = useState(false);
    const [verSearchAgente, setVerSearchAgente] = useState(false);
    const [accion, setAccion] = useState('alta');
    const [nivel, setNivel] = useState(2);
    const [msj, setMsj] = useState('');
    
    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [ name ]: value
        });
    }

    const setloading          = value=>setLoading(value);
    const setresult           = value=>setResult(value);
    const setverAltaAgente    = value=>setVerAltaAgente(value);
    const setverAltaUsuario   = value=>setVerAltaUsuario(value);
    const setverEditVerAgente = value=>setVerEditVerAgente(value);
    const setverSearchAgente  = value=>setVerSearchAgente(value);
    const setaccion           = value=>setAccion(value);
    const setnivel            = value=>setNivel(value);
    const setmsj              = value=>setMsj(value);
    const onResetForm         = () => {
        setFormState(initialForm);
        setMsj('');
    }

    return {
        ...formState,
        result,
        setresult,
        setloading,
        verAltaAgente,
        setverAltaAgente,
        verAltaUsuario,
        setverAltaUsuario,
        verEditVerAgente,
        setverEditVerAgente,
        verSearchAgente,
        setverSearchAgente,
        accion,
        setaccion,
        nivel,
        setnivel,
        msj,
        setmsj,
        loading,
        formState,
        onInputChange,
        onResetForm
    }
}