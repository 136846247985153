import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  result           : [],
  verAltaAgente    : false,
  verAltaUsuario   : false,
  verEditVerAgente : false,
  verSearchAgente  : false,
  volver           : false,
  accion           : 'alta',  
  nivel            : 2,
  msj              : ''
}

export const bodySlice = createSlice({
  name: 'body',
  initialState,
  reducers: {
    set_result           :(state,action) => {state.result           = action.payload},
    set_verAltaAgente    :(state,action) => {state.verAltaAgente    = action.payload},
    set_verAltaUsuario   :(state,action) => {state.verAltaUsuario   = action.payload},
    set_verEditVerAgente :(state,action) => {state.verEditVerAgente = action.payload},
    set_verSearchAgente  :(state,action) => {state.verSearchAgente  = action.payload}, 
    set_volver           :(state,action) => {state.volver           = action.payload},
    set_accion           :(state,action) => {state.accion           = action.payload},
    set_nivel            :(state,action) => {state.nivel            = action.payload},
    set_msj              :(state,action) => {state.msj              = action.payload}
  },
})

export const {
  set_result,
  set_verAltaAgente,
  set_verAltaUsuario,
  set_verEditVerAgente,
  set_verSearchAgente,
  set_volver,
  set_accion,
  set_nivel,
  set_msj
} = bodySlice.actions