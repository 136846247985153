import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  situacionList  : [],
  editaSituacion : false,
  togleIcon      : false,  
}

export const situacionSlice = createSlice({
  name: 'situacion',
  initialState,
  reducers: {
    set_situacion:(state, action)=>{
      state.situacionList = action.payload
    },
    set_editaSituacion:(state, action)=>{
      state.editaSituacion = action.payload
    },
    set_togleIcon:(state, action)=>{
      state.togleIcon = action.payload
    }
  },
})

export const { set_situacion, set_editaSituacion, set_togleIcon } = situacionSlice.actions