import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  login:false,
  msj:'',  
}


export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    set_login: (state, action) => {
      state.login = action.payload
    },
    set_msj: (state, action) => {
      state.msj = action.payload
    },
  },
})

export const { set_loading, set_login, set_msj } = loginSlice.actions