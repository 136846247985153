export const convertFecha = (fecha) => {
  
        let mes  = '';
        let dia  = '';
        let anio = '';
        let fechaResult = '';

        if(fecha[1]!==' '){
        dia=fecha[0]+fecha[1]
        }else{
        dia='0'+fecha[0];
        } 

        anio='20'+fecha[fecha.length-2]+fecha[fecha.length-1]

        if(fecha.includes('enero')){
        mes = '01';
        }else if(fecha.includes('febrero')){
        mes = '02';
        }else if(fecha.includes('marzo')){
        mes = '03';
        }else if(fecha.includes('abril')){
        mes = '04';
        }else if(fecha.includes('mayo')){
        mes = '05';
        }else if(fecha.includes('junio')){
        mes = '06';
        }else if(fecha.includes('julio')){
        mes = '07';
        }else if(fecha.includes('agosto')){
        mes = '08';
        }else if(fecha.includes('septiembre')){
        mes = '09';
        }else if(fecha.includes('octubre')){
        mes = '10';
        }else if(fecha.includes('noviembre')){
        mes = '11';
        }else if(fecha.includes('diciembre')){
        mes = '12';
        }

        fechaResult = anio+'-'+mes+'-'+dia;    

        return fechaResult;
    }