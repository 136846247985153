import { configureStore   } from '@reduxjs/toolkit';
import { loginSlice       } from './slices/login';
import { bodySlice        } from './slices/body';
import { situacionSlice   } from './slices/situacion';
import { suplementosSlice } from './slices/situacion';
import { pasesSlice       } from './slices/situacion';
import { conceptosSlice } from './slices/situacion';

export const store = configureStore({
    reducer: {
        login       : loginSlice.reducer,
        body        : bodySlice.reducer,
        situacion   : situacionSlice.reducer,
        suplementos : suplementosSlice.reducer,
        pases       : pasesSlice.reducer,
        conceptos   : conceptosSlice.reducer
    },
})