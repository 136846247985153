import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import { AppRouter } from "./router/AppRouter";

function App() {
  return (
    <div className="App">
      <header className="App-header"> 
        <Navbar/>
        
        <AppRouter/>
      </header>
    </div>
  );
}

export default App;
